<template>
  <tr
    :class="{'table--selected elevation-3': eventId === props.item.id}"
    tabindex="1"
    @keydown.enter="selectEvent(props.item)"
    @click="selectEvent(props.item)">
    <td>{{ props.item.name }}</td>
    <td class="text-center">
      <v-icon
        v-if="props.item.proposal && props.item.proposal.has"
        color="darkGray"
        title="Sim">
        {{ $root.icons.state.actived }}
      </v-icon>
      <v-icon
        v-else
        color="grey"
        title="Não">
        {{ $root.icons.state.disabled }}
      </v-icon>
    </td>
    <td class="text-center">
      <v-icon
        v-if="props.item.negotiation && props.item.negotiation.has"
        color="darkGray"
        title="Sim">
        {{ $root.icons.state.actived }}
      </v-icon>
      <v-icon
        v-else
        color="grey"
        title="Não">
        {{ $root.icons.state.disabled }}
      </v-icon>
    </td>
    <td class="text-center">
      <v-icon
        title="Não"
        color="grey">
        {{ $root.icons.state.disabled }}
      </v-icon>
    </td>
    <td>
      <label-icon
        v-if="props.item.email && props.item.email.send"
        type="Email" />
      <label-icon
        v-if="props.item.sms && props.item.sms.send"
        type="Sms" />
      <label-icon
        v-if="props.item.letter && props.item.letter.send"
        type="Carta" />
    </td>
  </tr>
</template>

<script>
  const LabelIcon = () => import('@/components/ui/LabelIcon')

  export default {
    components: {
      LabelIcon
    },
    props: {
      eventId: {
        type: String,
        default: null
      },
      props: Object
    },
    methods: {
      selectEvent (item) {
        this.$emit('selectEvent', item)
      }
    }
  }
</script>

<style lang="sass">
  .table--selected
    background: #eee

</style>
